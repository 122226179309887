<template>
  <div class="write-page-reivew-container">
    <WriteStrokeReview
      :strokeImgList="strokeImgList"
      :showImgList="showImgList"
      :pinyinHelper="pinyinHelper"
    />
  </div>
</template>
<script>
import WriteStrokeReview from "@/components/Course/CoursePage/WriteStrokeReview";

export default {
  data() {
    return {
      pinyinList: ["wògōu", "xiégōu", "héngzhé tí"],
      pinyinHelper: ["wogou", "xiegou", "hengzhe ti"],
    };
  },
  computed: {
    strokeImgList() {
      let imgArr = [];
      for (let i = 1; i <= 3; i++) {
        const ele = {
          strokeImg: require(`@/assets/img/16-GAMES/G52-stroke-names/stroke-${
            this.pinyinHelper[i - 1]
          }.svg`),
          redStrokeImg: require(`@/assets/img/16-GAMES/G52-stroke-names/stroke-${
            this.pinyinHelper[i - 1]
          }.svg`),
          pinyin: this.pinyinList[i - 1],
        };
        imgArr.push(ele);
      }
      return imgArr;
    },
    showImgList() {
      let imgArr = [];
      for (let i = 1; i <= 3; i++) {
        const ele = {
          showImg: require(`@/assets/img/16-GAMES/G52-stroke-names/stroke-${
            this.pinyinHelper[i - 1]
          }-beige.svg`),
          showRedImg: require(`@/assets/img/16-GAMES/G52-stroke-names/stroke-${
            this.pinyinHelper[i - 1]
          }-red.svg`),
        };
        imgArr.push(ele);
      }
      return imgArr;
    },
  },
  components: {
    WriteStrokeReview,
  },
};
</script>
<style lang="scss" scoped>
.write-page-reivew-container {
  width: 100%;
  height: 100%;
}
</style>
