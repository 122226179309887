<template>
  <div class="game-container">
    <ReviewTrain
      :step="1"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "qù",
            hanzi: "去",
          },
          {
            pinyin: "xuéxiào",
            hanzi: "学校",
          },
          {
            pinyin: "shāngdiàn",
            hanzi: "商店",
          },
          {
            pinyin: "chāoshì",
            hanzi: "超市",
          },
          {
            pinyin: "nǐmen",
            hanzi: "你们",
          },
          {
            pinyin: "wǒmen",
            hanzi: "我们",
          },
        ],
        [
          {
            pinyin: "fàndiàn",
            hanzi: "饭店",
          },
          {
            pinyin: "kǎoyā",
            hanzi: "烤鸭",
          },
          {
            pinyin: "gāoxìng",
            hanzi: "高兴",
          },
          {
            pinyin: "rènshi",
            hanzi: "认识",
          },
          {
            pinyin: "gōngyuán",
            hanzi: "公园",
          },
          {
            pinyin: "duō",
            hanzi: "多",
          },
        ],
        
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "......qù nǎr?",
            hanzi: "......去哪儿？",
          },
          {
            pinyin: "māma / wǒmen",
            hanzi: "你们/我们",
          },
          {
            pinyin: "hěn gāoxìng rènshi nǐ",
            hanzi: "很高兴认识你",
          },
         
        ],
      
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "an",
          },
          {
            vocabulary: "en",
          },
          {
            vocabulary: "in",
          },
          {
            vocabulary: "un",
          },
          {
            vocabulary: "ün",
          },
        ],
        [
          {
            vocabulary: "ang",
          },
          {
            vocabulary: "eng",
          },
          {
            vocabulary: "ing",
          },
          {
            vocabulary: "ong",
          },
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-50/50-wogou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-52/52-xiegou-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-54/54-hengzheti-white.svg"),
          },
          
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>