<template>
  <div class="game-container">
    <FrogJumpGame
      :bgImg="bgImg"
      :durationArr="durationArr"
      :imgInfo="imgInfo"
      :lessonNum="lessonNum"
    />
  </div>
</template>

<script>
import FrogJumpGame from "@/components/Course/GamePage/FrogJumpGame";
export default {
  created() {
    // console.log(this.durationArr.length);
  },
  data() {
    return {
      lessonNum:16,
      bgImg: require("@/assets/img/16-GAMES/G12-map-letters/map-pinyin-chapter-7.svg"),
      durationArr: [
        0, 0, 0.03, 0.06, 0.1, 0.15, 0.17, 0.2, 0.26, 0.32, 0.38, 0.44, 0.5,
        0.57, 0.63, 0.65, 0.7, 0.78, 0.87, 0.9,1
      ],
      imgInfo: {
        frogImg: require("@/assets/img/16-GAMES/G12-map-letters/frog.svg"),
        boxClosedImg: "",
        boxOpenImg: "",
      },
    };
  },
  components: {
    FrogJumpGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











