<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "wǒ",
          tipsList: ["我", "在", "学", "校", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-52/hanzi-wo-grey-border.svg"),
          stepNumber: 7,
          tipsIndex: 0,
        },
        {
          pinyin: "men",
          tipsList: ["我", "们", "不", "在", "家", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-52/hanzi-men-grey-border.svg"),
          stepNumber: 5,
          tipsIndex: 1,
        },
        {
          pinyin: "rèn",
          tipsList: ["很", "高", "兴", "认", "识", "你", "。"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-14/hanzi-ren-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 3,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-56/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrMa = [];
      for (let i = 1; i <= 4; i++) {
        imgArrMa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-54/lesson-54-ren-${i}.gif`)
        );
      }
      let imgArrYou = [];
      for (let i = 1; i <= 5; i++) {
        imgArrYou.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-52/lesson-52-men-${i}.gif`)
        );
      }
      let imgArrRen = [];
      for (let i = 1; i <= 7; i++) {
        imgArrRen.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-52/lesson-52-wo-${i}.gif`)
        );
      }
      return [imgArrRen, imgArrYou, imgArrMa];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
