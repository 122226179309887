<template>
  <div class="game-container">
    <ListenGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :titleInfo="titleInfo"
      :chooseHanziList="chooseHanziList"
    />
  </div>
</template>

<script>
import ListenGame from "@/components/Course/GamePage/ListenGame";
export default {
  data() {
    return {
      answerList: [3, 8, 10,],
      titleInfo:{
        pinyin:"Hěn gāoxìng rènshi nǐ.",
        hanzi:"很高兴认识你。"
      },
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-56/image-3.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/hen-gaoxing-renshi-ni.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-56/image-8.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/hen-gaoxing-renshi-ni.mp3"),
    
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-56/image-10.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/hen-gaoxing-renshi-ni.mp3"),
        },
        
      
      ],
    };
  },
  computed: {
    allImgList() {
      let imgArr = [];
      for (let i = 1; i <= 12; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-56/image-${i}.svg`),
          id: i,
        });
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(8, 12),
      ];
    },
  },
  components: {
    ListenGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>