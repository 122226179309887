<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [{
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-49-xuexiao.svg"),
          sound: require("@/assets/audio/L1/5-Words/xuexiao.mp3")
        },
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-51-fandian.svg"),
          sound: require("@/assets/audio/L1/5-Words/fandian.mp3")
        },
        {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-49-shangdian.svg"),
          sound: require("@/assets/audio/L1/5-Words/shangdian.mp3")
        },
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-53-gaoxing.svg"),
          sound: require("@/assets/audio/L1/5-Words/gaoxing.mp3")
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-53-gongyuan.svg"),
          sound: require("@/assets/audio/L1/5-Words/gongyuan.mp3")
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-49-chaoshi.svg"),
          sound: require("@/assets/audio/L1/5-Words/chaoshi.mp3")
        },
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "学校",
            pinyin: "xuéxiào",
            sound: require("@/assets/audio/L1/5-Words/xuexiao.mp3")
          },
          {
            id: 2,
            element: "超市",
            pinyin: "chāoshì",
            sound: require("@/assets/audio/L1/5-Words/chaoshi.mp3")
          },
          {
            id: 3,
            element: "商店",
            pinyin: "shāngdiàn",
            sound: require("@/assets/audio/L1/5-Words/shangdian.mp3")
          }
        ],
        rightList: [
          {
            id: 4,
            element: "饭店",
            pinyin: "fàndiàn",
            sound: require("@/assets/audio/L1/5-Words/fandian.mp3")
          },
          {
            id: 5,
            element: "公园",
            pinyin: "gōngyuán",
            sound: require("@/assets/audio/L1/5-Words/gongyuan.mp3")
          },
          {
            id: 6,
            element: "高兴",
            pinyin: "gāoxìng",
            sound: require("@/assets/audio/L1/5-Words/gaoxing.mp3")
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicReviewGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
