<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
          {
            index: 1,
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-xing.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-shui.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-er.svg"),
          },
        ],
        [
          
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-mi.svg"),
           
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-dian.svg"),
             isAnswer: true,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-you.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-chang.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-me.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/lesson-56/drop-qu.svg"),
            isAnswer: true,
            index: 3,
          },
        ],
      ],

      answerList: [
        [
          
          {
            hanzi: "高",
            pinying: "gāo",
            isChooseCorrect: true,
          },
          {
            id: 1,
            hanzi: "兴",
            pinying: "xìng",
            isChooseCorrect: false,
          },
        ],
        [
          {
            hanzi: "商",
            pinying: "shāng",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "店",
            pinying: "diàn",
            isChooseCorrect: false,
          },
         
        ],
        [
          {
            id: 3,
            hanzi: "去",
            pinying: "qù",
            isChooseCorrect: false,
          },
          {
            hanzi: "哪",
            pinying: "nǎ",
            isChooseCorrect: true,
          },
          {
            hanzi: "儿",
            pinying: "èr",
            isChooseCorrect: true,
          },
          
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











