<template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :limitNumberOfVisibleOptions="3" :isFullScreen="true" :isHaveAudio="true"/>
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 8,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-an.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-en.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-in.svg"),
        },
         {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-un.svg"),
        },
         {
          id: 5,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-vn.svg"),
        },
         {
          id: 6,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-ang.svg"),
        },
         {
          id: 7,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-eng.svg"),
        },
         {
          id: 8,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-ing.svg"),
        },
         {
          id: 9,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/background-ong.svg"),
        }
      ],
      imgList: [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-an.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/an.mp3"),
        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-en.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/en.mp3"),
        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-in.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/in.mp3"),
        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-un.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/un.mp3"),
        },
        {
          id: 5,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-vn.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/vn.mp3"),
        },
        {
          id: 6,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-ang.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ang.mp3"),
        },
        {
          id: 7,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-eng.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/eng.mp3"),
        },
        {
          id: 8,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-ing.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ing.mp3"),
        },
        {
          id: 9,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-07/frog-ong.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/ong.mp3"),
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











