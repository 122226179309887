<template>
  <div class="game-container">
    <PinyinMoveGame
      :bgImg="bgImg"
      :studentImg="studentImg"
      :lessonNum="lessonNum"
      :durationArr="durationArr"
      :isTwoImg="true"
    />
  </div>
</template>

<script>
import PinyinMoveGame from "@/components/Course/GamePage/PinyinMoveGame";
export default {
  data() {
    return {
      lessonNum:56,
      bgImg: require("@/assets/img/16-GAMES/G12-map/lesson-56-map.svg"),
      // frontImg: require("@/assets/img/16-GAMES/G12-map/lesson-50-map-2.svg"),
      studentImg: require("@/assets/img/16-GAMES/G12-map/lesson-50-student.svg"),
      durationArr: [0.08,0.3,0.65,],
    };
  },
  components: {
    PinyinMoveGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











